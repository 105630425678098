import React from "react"
import { Elements } from "prismic-reactjs"
import { linkResolver } from "./linkResolver"

// -- Function to add unique key to props

const propsWithUniqueKey = function(props, key) {
  return Object.assign(props || {}, { key })
}

// -- HTML Serializer

// This function will be used to change the way the HTML is loaded

const htmlSerializer = function(type, element, content, children, key) {
  var props = {}

  switch (type) {
    case Elements.heading1: // Heading 1
      return React.createElement("h1", propsWithUniqueKey(props, key), children)

    case Elements.heading2: // Heading 2
      return React.createElement("h2", propsWithUniqueKey(props, key), children)

    case Elements.heading3: // Heading 3
      return React.createElement("h3", propsWithUniqueKey(props, key), children)

    case Elements.heading4: // Heading 4
      return React.createElement("h4", propsWithUniqueKey(props, key), children)

    case Elements.heading5: // Heading
      return React.createElement("h5", propsWithUniqueKey(props, key), children)

    // Add a class to paragraph elements
    case Elements.paragraph:
      props = { className: "u-text-medium" }

      if (children[0]) {
        return React.createElement(
          "p",
          propsWithUniqueKey(props, key),
          children
        )
      } else {
        props = { className: "o-spacer" }
        return React.createElement("div", propsWithUniqueKey(props, key))
      }

    // Don't wrap images in a <p> tag
    case Elements.image:
      props = {
        src: element.url,
        alt: element.alt || "",
        className: `o-inline-img ${element.label}`,
      }
      return React.createElement("img", propsWithUniqueKey(props, key))

    // Add a class to hyperlinks
    case Elements.hyperlink:
      const targetAttr = element.data.target
        ? { target: element.data.target }
        : {}
      const relAttr = element.data.target ? { rel: "noopener" } : {}

      props = Object.assign(
        {
          href: element.data.url || linkResolver(element.data),
        },
        targetAttr,
        relAttr
      )

      return React.createElement("a", propsWithUniqueKey(props, key), children)

    // Return null to stick with the default behavior
    default:
      return null
  }
}

export default htmlSerializer
